import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { useApi } from '../../hooks/useApi';
import { editCompnayRates, getCompanyRates } from '../../api/CompanyApi';
import { FormValidator } from '../FormValidator/FormValidator';
import { CompanyRatesValidationConfig } from '../../utils/formValidatorConfigs/config';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';
import { getDefaultTariffs } from '../../api/TariffApi';
import { CurrencyInputComponent } from '../UI/Fields/CurrencyField';
import { floatMask } from 'float-mask';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';
import { useNavigate } from 'react-router-dom';

export const CompanyRates = ({ company, wasChangeHandler, permission }) => {
  const [rates, setRates] = useState([
    {
      version: 'Lite',
      companyId: company.id,
      percentageCommission: null,
      fixCommission: null,
    },
    {
      version: 'Medium',
      companyId: company.id,
      percentageCommission: null,
      fixCommission: null,
    },
    {
      version: 'Comfort',
      companyId: company.id,
      percentageCommission: null,
      fixCommission: null,
    },
  ]);
  const [paymentCompanyId, setPaymentCompanyId] = useState('');
  const getCompnayRatesApi = useApi(getCompanyRates);
  const editCompnayRatesApi = useApi(editCompnayRates);
  const getDefaultTariffsApi = useApi(getDefaultTariffs);
  const [validateErrors, setValidateErrors] = useState([]);
  const [defaultPrices, setDefaultPrices] = useState({});
  const [wasChange, setChange] = useState(false);
  const [customValidateErrors, setCustomValidateErrors] = useState({});

  const config = useMemo(() => CompanyRatesValidationConfig(), []);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  useEffect(() => {
    if (company?.id) {
      getDefaultTariffsApi.sendRequest().then((result) => {
        const newResult = result.map((r) => ({
          version: r.version,
          defaultCommission: '' + r.defaultCommission,
        }));
        setDefaultPrices({
          Lite:
            '' + newResult.find((r) => r.version == 'Lite').defaultCommission,
          Medium:
            '' + newResult.find((r) => r.version == 'Medium').defaultCommission,
          Comfort:
            '' +
            newResult.find((r) => r.version == 'Comfort').defaultCommission,
        });
      });
      getCompnayRatesApi.sendRequest(company.id).then((result) => {
        setRates(result);
      });
      setPaymentCompanyId(company.shopCode + '');
    }
  }, [company]);
  const dispatch = useDispatch();
  const editCompnayRatesHandler = async () => {
    wasChangeHandler(false);
    const result = await editCompnayRatesApi.sendRequest(
      rates.map((r) => ({
        ...r,
        fixCommission:
          r.fixCommission || r.fixCommission == 0
            ? '' + valueHandler(r.fixCommission)
            : null,
        shopId: paymentCompanyId,
      })),
    );
    dispatch(setAlert({ status: 201, text: 'Информация успешно сохранена' }));
  };

  const valueHandler = (data) => {
    if (data && ('' + data).split('').filter((s) => s === '.').length > 1) {
      return (data + '').replace('.', '');
    } else {
      return data;
    }
  };

  useEffect(() => {
    const Lite = rates?.find((r) => r.version == 'Lite');

    const Comfort = rates?.find((r) => r.version == 'Comfort');
    const Medium = rates?.find((r) => r.version == 'Medium');
    setCustomValidateErrors({
      Lite:
        Lite?.fixCommission < 1 && Lite.fixCommission !== ''
          ? { text: 'Значение не должно быть меньше 1' }
          : { text: null },
      Medium:
        Medium?.fixCommission < 1 && Medium.fixCommission !== ''
          ? { text: 'Значение не должно быть меньше 1' }
          : { text: null },
      Comfort:
        Comfort?.fixCommission < 1 && Comfort.fixCommission !== ''
          ? { text: 'Значение не должно быть меньше 1' }
          : { text: null },
    });
  }, [rates]);
  const navigate = useNavigate();
  return (
    <div>
      <FormValidator
        name="compnay-rate-form-name"
        validationConfig={config}
        changed={rates || paymentCompanyId}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Grid container spacing={2} sx={{ mt: 3 }}>
          {rates?.map((r) => (
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="fixCommission"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={r?.fixCommission}
                id={r?.version}
                label={`Стоимость  Eltis по тарифу "${r?.version}"(₽)`}
                disabled={!!r?.percentageCommission || !permission?.edit}
                placeholder="Минимальная стоимость тарифа"
                required={config.fixCommission.isRequired}
                error={
                  validateErrors?.fixCommission?.text ||
                  customValidateErrors[r.version]?.text
                }
                helperText={
                  validateErrors?.fixCommission?.text ||
                  customValidateErrors[r.version]?.text
                }
                type="number"
                defaultValue="00.00"
                onInput={({ target: { value } }) => {
                  setRates(rates);
                  setChange(true);
                  wasChangeHandler(true);
                }}
                onChange={({ target: { value } }) => {
                  setRates([
                    ...rates?.map((rate) => {
                      if (rate.version == r.version) {
                        rate.fixCommission = floatMask(value, '0.00');
                      }
                      return rate;
                    }),
                  ]);
                }}
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
          ))}
        </Grid>

        <FooterButtonBar
          showDeleteButton={false}
          cancelButtonHandler={() => navigate(-1)}
          showCancelButton={true}
          showUpdateButton={false}
          showCreateButton={permission?.edit}
          createButtonHandler={() => editCompnayRatesHandler()}
          cancelButtonText="Назад"
          disabledCreateButton={
            !wasChange ||
            Object.values(customValidateErrors).some((i) => i?.text)
          }
          createButtonText="Сохранить"
          loading={
            getCompnayRatesApi.loading ||
            editCompnayRatesApi.loading ||
            getDefaultTariffsApi.loading
          }
        />
      </FormValidator>
    </div>
  );
};
