import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getOneCompany } from '../../api/CompanyApi';
import { URLS } from '../../utils/urls.util';
import { EmployeeList } from '../EmployeeList/EmployeeList';
import { useApi } from '../../hooks/useApi';
import { getAppSettings } from '../../api/AppSettings';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { SingleCompanyNavigation } from './SingleCompanyNavigation';

export const SingleCompanyEmployees = ({ permission }) => {
  const getOneCompanyApi = useApi(getOneCompany);
  const getAppSettingsApi = useApi(getAppSettings);
  const [isCreate, setIsCreate] = useState(false);
  const [company, setCompany] = useState({});
  const [appSettings, setAppSettings] = useState({});
  const { id } = useParams();

  useEffect(() => {
    if (id !== 'create') {
      getOneCompanyApi
        .sendRequest(id, ['meta', 'rates'])
        .then((company) => setCompany(company));
    }
    getAppSettingsApi.sendRequest().then((result) => setAppSettings(result));
  }, []);

  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Typography key="3" color="text.primary">
      {isCreate ? 'Новая организация' : company.title}
    </Typography>,
    <Typography key="3" color="text.primary">
      Сотрудники
    </Typography>,
  ];

  return (
    (id || isCreate) && (
      <FullPageLayout
        obj={company}
        seoTitle={'Организация - ' + (company?.title || 'Добавить организацию')}
        pageTitle={isCreate ? 'Добавить организацию' : company.title}
        isCreated={isCreate}
        breadcrumbs={breadcrumbs}
        showHiddenData={true}
      >
        <SingleCompanyNavigation
          company={company}
          activeTab={4}
          appSettings={appSettings}
        />

        <EmployeeList permission={permission} company={company} key="2" />
      </FullPageLayout>
    )
  );
};
