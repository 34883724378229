import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { EmployeeValidationConfig } from '../../utils/formValidatorConfigs/config';
import { EmployeeFormActionButtons } from '../EmployeeFormActionButtons/EmployeeFormActionButtons';
import { FormValidator } from '../FormValidator/FormValidator';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';
import { useApi } from '../../hooks/useApi';
import {
  getAllUsersByOrganizationId,
  inviteUserAgain,
} from '../../api/UserApi';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { getDefaultRates } from '../../api/CompanyApi';

export const EmployeeForm = ({
  company,
  employee,
  newEmployeeHandler,
  changeEmployeeAfterCreateOrUpdateHandler,
  permission,
}) => {
  const [employeeState, setEmployeeState] = useState({ email: '' });
  const [validateErrors, setValidateErrors] = useState([]);
  const config = useMemo(() => EmployeeValidationConfig(), []);
  const [formDisabled, setFormDisabled] = useState(true);
  const [wasChange, setChange] = useState(false);
  const [checkChangeAlways, setCheckChangeAlways] = useState(false);
  const inviteUserAgainApi = useApi(inviteUserAgain);
  const [currentEmployeeLimit, setCurrentEmployeeLimit] = useState(null);
  const [employees, setEmployees] = useState(0);
  const [wasUpdata, setWasUpdata] = useState(false);
  const userApi = useApi(getAllUsersByOrganizationId);
  const [openApprove, setOpenApprove] = useState(false);
  const getDeafultRatesApi = useApi(getDefaultRates);
  const getEmployees = async (offset = 0) => {
    const [result, count] = await userApi.sendRequest(
      99999,
      offset,
      {
        q: `organizationId:${company.id}`,
      },
      ['/OrganizationAdministrator'],
    );

    setEmployees(result);
    return result;
  };
  useEffect(() => {
    getEmployees().then((result) => {
      getDeafultRatesApi.sendRequest().then((res) => {
        const rateEmployeeCount =
          company?.Version == 0
            ? res.find((r) => r.name === 'Basic').employeeCount
            : company?.Version == 1
            ? res.find((r) => r.name === 'Advanced').employeeCount
            : 99999999;

        const currentActiveEmployees = result?.filter(
          (e) => e.enabled === true,
        )?.length;

        setCurrentEmployeeLimit(
          rateEmployeeCount - currentActiveEmployees > 0
            ? rateEmployeeCount - currentActiveEmployees
            : 0,
        );
      });
    });
  }, [employeeState]);
  useEffect(() => {
    let inputs =
      document.forms['employee-form-name'].getElementsByTagName('input');
    let elements = Array.prototype.slice.call(inputs);
    if (elements.length) {
      elements = elements.filter((i) => i.name);
      const array = elements.map((i) => ({
        required: i.required,
        value: i.value,
      }));

      setFormDisabled(array.some((i) => i.required && !i.value));
    }
  }, [employeeState, validateErrors]);

  useEffect(() => {
    setCheckChangeAlways(wasChangedHandler());
  }, [employeeState, employee]);

  useEffect(() => {
    if (employee) {
      setEmployeeState({
        ...employee,
        enabled: employee?.id ? employee?.enabled : true,
        // email: employee?.email || '',
        phone: employee?.attributes?.phone,
        middleName: employee?.attributes?.middleName,
      });
    }
  }, [employee]);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const onChange = (value, fieldName) => {
    if (!wasChange && value != '+7(___)___-__-__') {
      setChange(true);
    }
    setEmployeeState({ ...employeeState, [fieldName]: value });
  };

  const wasChangedHandler = () => {
    const checker = [];
    if (employeeState && employee) {
      for (const i in employeeState) {
        if (i && employeeState[i] && employee[i]) {
          checker.push(employeeState[i] == employee[i]);
        }
      }
      return checker.some((c) => !c);
    }
    return false;
  };
  const dispatch = useDispatch();
  const invateAgainHandler = async () => {
    await inviteUserAgainApi.sendRequest(employeeState?.id);
    dispatch(
      setAlert({
        text: 'Приглашение повторно отправлено на почту пользователя.',
        status: 200,
      }),
    );
  };

  return (
    <>
      <FormValidator
        name="employee-form-name"
        validationConfig={config}
        changed={employeeState}
        setErrorsHandler={(data) => handlerError(data)}
      >
        {!employeeState?.emailVerified &&
          employeeState?.id &&
          permission?.edit && (
            <Button
              variant="outlined"
              onClick={() => invateAgainHandler()}
              sx={{ mt: 2, mb: 2 }}
              disabled={!employee.enabled}
            >
              Пригласить повторно в АРМ
            </Button>
          )}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Фамилия"
              variant="outlined"
              sx={{ mt: 2 }}
              name="lastName"
              required={config.lastName.isRequired}
              error={wasChange && validateErrors?.lastName?.text}
              helperText={wasChange && validateErrors?.lastName?.text}
              value={employeeState?.lastName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'lastName')}
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Имя"
              variant="outlined"
              sx={{ mt: 2 }}
              name="firstName"
              required={config.firstName.isRequired}
              error={wasChange && validateErrors?.firstName?.text}
              helperText={wasChange && validateErrors?.firstName?.text}
              value={employeeState?.firstName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'firstName')}
              disabled={!permission?.edit}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Отчество"
              variant="outlined"
              sx={{ mt: 2 }}
              name="middleName"
              required={config.middleName.isRequired}
              error={wasChange && validateErrors?.middleName?.text}
              helperText={wasChange && validateErrors?.middleName?.text}
              value={employeeState?.middleName}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(target.value, 'middleName')}
              disabled={!permission?.edit}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="email"
              variant="outlined"
              sx={{ mt: 2 }}
              value={employeeState?.email}
              disabled={
                (!!employee?.email && employee?.createdInAws) ||
                !permission?.edit
              }
              name="email"
              required={config.email.isRequired}
              error={validateErrors?.email?.text}
              helperText={validateErrors?.email?.text}
              InputLabelProps={{
                shrink: true,
              }}
              onClick={() => !employeeState?.email && onChange('', 'email')}
              onChange={({ target }) => onChange(target.value, 'email')}
            />
          </Grid>

          <Grid item xs={4}>
            <InputMask
              mask="+7(999)999-99-99"
              fullWidth
              label="Телефон"
              variant="outlined"
              sx={{ mt: 2 }}
              value={employeeState?.phone}
              name="phone"
              required={config.phone.isRequired}
              error={
                employeeState?.phone && wasChange && validateErrors?.phone?.text
              }
              helperText={
                employeeState?.phone && wasChange && validateErrors?.phone?.text
              }
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) =>
                permission?.edit && onChange(target.value, 'phone')
              }
              disabled={!permission?.edit}
            >
              {(props) => <TextField disabled={!permission?.edit} {...props} />}
            </InputMask>
          </Grid>

          <Grid item xs={4}>
            {currentEmployeeLimit !== null && (
              <FormControlLabel
                sx={{ width: '33%', mt: 3, cursor: 'pointer' }}
                control={
                  <Switch
                    checked={employeeState.enabled}
                    disabled={
                      !permission?.edit
                      // ||
                      // (!currentEmployeeLimit && !employeeState.enabled)
                    }
                    onChange={({ target: { checked } }) => {
                      if (checked && !currentEmployeeLimit) {
                        setOpenApprove(true);
                      } else {
                        onChange(checked, 'enabled');
                      }
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={employeeState.enabled ? 'Активен' : 'Неактивен'}
              />
            )}
            <Typography sx={{ color: '#fff' }}>
              {currentEmployeeLimit}
            </Typography>
          </Grid>
        </Grid>

        <ActionApproveV2
          open={openApprove}
          title={`Вы не можете изменить статус сотрудника на Активен, т.к. лимит на создание пользователей по тарифу  "${
            company?.Version == 1 ? 'Advanced' : 'Basic'
          }" организации исчерпан`}
          handleClose={() => setOpenApprove(false)}
          showDescription={false}
          handleSuccess={() => setOpenApprove()}
          showCloseButton={false}
          handleSuccessText="Понятно"
        />

        <EmployeeFormActionButtons
          disabled={
            formDisabled || !!Object.keys(validateErrors).length || !wasChange
          }
          company={company}
          employeee={employeeState}
          permission={permission}
          wasChanged={checkChangeAlways}
          wasChangeHandler={() => setCheckChangeAlways(false)}
          changeEmployeeAfterCreateOrUpdateHandler={(data) => {
            changeEmployeeAfterCreateOrUpdateHandler(data);
            setWasUpdata(true);
          }}
        />
      </FormValidator>
    </>
  );
};
